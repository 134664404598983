import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { LossePlaatjie as Image, LosseLink as Link } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="FormContact" className={clsx(!fields.showcontactinfo && 'section', 'relative')}>
      <div className="container">
        <div className={clsx(fields.showcontactinfo ? 'grid-cols-2 lg:grid-cols-3' : 'grid-cols-2', 'grid lg:gap-12 2xl:gap-32')}>
          {fields.showcontactinfo && (
            <div className="col-span-2 lg:col-span-1">
              <div className="bg-cold-morning py-6 s:py-10 lg:py-16 xl:py-24 px-6 lg:px-0 xl:px-10 relative before:content-none before:absolute before:top-0 before:h-full before:w-[2000px] before:-left-[2000px] before:bg-cold-morning">
                <Link to={fields.call?.link?.url} className="flex group items-center gap-6">
                  <div className="bg-sprout group-hover:bg-outrageous-orange transition-all duration-200 flex justify-center items-center rounded-full p-4">
                    <Image src={fields.call?.icon} alt="call-us" />
                  </div>
                  <div>
                    <div className="font-nord font-medium text-xl mb-1">{fields.call?.label}</div>
                    <div className="font-prompt group-hover:underline font-light text-xl">{fields.call?.link?.title}</div>
                  </div>
                </Link>
                <Link to={fields.callSpoed?.link?.url} className="flex group items-center gap-6 pt-10 xl:pt-16">
                  <div className="bg-sprout group-hover:bg-outrageous-orange transition-all duration-200 flex justify-center items-center rounded-full p-4 min-w-[80px] min-h-[80px]">
                    <Image src={fields.callSpoed?.icon} alt="call-us" />
                  </div>
                  <div>
                    <div className="font-nord font-medium text-xl mb-1">{fields.callSpoed?.label}</div>
                    <div className="font-prompt group-hover:underline font-light text-xl">{fields.callSpoed?.link?.title}</div>
                    <p className="mb-0 mt-2 italic">{fields?.callSpoed?.description}</p>
                  </div>
                </Link>
                <Link to={fields.mail?.link?.url} className="flex group items-center gap-6 pt-10 xl:pt-16">
                  <div className="bg-sprout group-hover:bg-outrageous-orange transition-all duration-200 flex justify-center items-center rounded-full p-4">
                    <Image src={fields.mail?.icon} alt="mail-us" />
                  </div>
                  <div>
                    <div className="font-nord font-medium text-xl mb-1">{fields.mail?.label}</div>
                    <div className="font-prompt group-hover:underline font-light text-xl">{fields.mail?.link?.title}</div>
                  </div>
                </Link>
                {fields.appointment?.link && (
                  <a href={fields.appointment?.link?.url || '#contact-form'} className="flex group items-center gap-6 pt-10 xl:pt-16">
                    <div className="bg-sprout group-hover:bg-outrageous-orange transition-all duration-200 flex justify-center items-center rounded-full p-4">
                      <Image src={fields.appointment?.icon} alt="mail-us" />
                    </div>
                    <div>
                      <div className="font-nord font-medium text-xl mb-1">{fields.appointment?.label}</div>
                      <div className="font-prompt group-hover:underline font-light text-xl">{fields.appointment?.link?.title}</div>
                    </div>
                  </a>
                )}

                <Content className="pt-10 xl:pt-16 children-p:font-prompt children-p:font-light children-h2:text-2xl s:children-h2:text-3xl children-h2:pb-2 s:children-h2:pb-5 children-strong:!font-medium children-h2:font-nord">
                  {fields.address}
                </Content>
              </div>
              {/* <div className="absolute rotate-[30deg] top-full lg:-bottom-1/2 left-[672px] w-[600px] h-[3000px] bg-mine-shaft bg-opacity-5" /> */}
            </div>
          )}

          <div className="col-span-2">
            <div
              className={clsx(
                !fields.showcontactinfo && 'max-w-5xl mx-auto',
                'bg-white shadow-feed px-6 s:px-16 xl:px-32 py-6 s:py-12 xl:py-16'
              )}
            >
              <Content
                className={clsx(
                  !fields.showcontactinfo &&
                    'max-lg:children-p:leading-6 children-p:text-lg lg:children-p:text-[22px] children-p:font-medium',
                  'content'
                )}
              >
                {fields?.description}
              </Content>
              <div className="mt-5 lg:mt-10" id="contact-form">
                <Form
                  data={fields.form}
                  captchaSiteKey="6LfZylIgAAAAAI80YNx6UcMFt1f2uBzJvRitDMoB"
                  privacyUrl="/privacy"
                  privacyUrlPlacement="privacy"
                  renderLoading={() => <Loading />}
                  className="form-contact"
                  renderStatus={({ status, content }) => <Content>{content}</Content>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
